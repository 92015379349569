:root {
  --base-padding: 16px;
  --base-margin: 16px;
  --base-border: 16px;
  --color: #009688;
  --color-darken1: #00897b;
  --color-darken2: #00796b;
  --color-darken3: #00695c;
  --color-darken4: #004d40;
  --color-lighten1: #26a69a;
  --color-lighten2: #4db6ac;
  --color-lighten3: #80cbc4;
  --color-lighten4: #b2dfdb;
  --color-lighten5: #e0f2f1;
  --project-card: 300px;
}

.color {
  background: var(--color);
  color: white;
}

.color-darken-1 {
  background: var(--color-darken1);
  color: white;
}

.color-darken-2 {
  background: var(--color-darken2);
  color: white;
}

.color-darken-3 {
  background: var(--color-darken3);
  color: white;
}

.color-darken-4 {
  background: var(--color-darken4);
  color: white;
}

a {
  text-decoration: underline;
  color: var(--color-darken3);
  font-weight: bold;
}

a:hover {
  color: var(--color-darken4);
}

.gen-img {
  border-radius: var(--base-border);
  border: 4px solid var(--color-darken3);
  display: flex;
  justify-content: center;
  max-width: 50%;
}

.gen-section {
  display: flex;
  width: 80%;
  margin: 0 auto;
  justify-content: space-around;
  align-items: center;
}

.gen-text {
  margin: 0 calc(3*var(--base-margin));
}

.box {
  border: 1px solid blue;
}

.embed-test {
  width: 90%;
  min-height: 500px;
}

.tokens {
  display: flex;
  flex-wrap: nowrap;
}

.single-token {
  background: var(--color);
  color: white;
  margin: var(--base-margin);
  padding: var(--base-padding);
  border-radius: var(--base-border);
  border: var(--color-darken4) 8px solid;
  text-align: center;
  font-weight: bold;
  font-size: 48pt;
  width: 100%;
}

.token-back {
  background-color: var(--color-darken4);
  color: var(--color-darken4);
  border: var(--color-darken4) 8px solid;
  border-radius: var(--base-border);
  padding: var(--base-padding);
  margin: var(--base-margin);
  text-align: center;
  font-weight: bold;
  font-size: 48pt;
  width: 100%;

}

.full-area-padding h2 {
  font-size: 36px;
  text-decoration: underline;
}

body {
  font-family: 'PT Sans Narrow', sans-serif;
  font-size: 14pt;
}

h1, h2, h3, h4 {
  width: 100%;
  text-align: center;
  font-family: 'Oleo Script', sans-serif;
}

.full-area {
  width: 100%;
  height: 100%;
}

.full-area-padding {
  width: calc(100% - var(--base-padding));
  height: calc(100% - var(--base-padding));
}

.mobile-tableau {
  display: none;
}

.section {
  background: white;
  box-shadow: 10px 10px 24px 0px rgba(0,0,0,0.5);
}

#vizContainer {
  margin: calc(2*var(--base-margin)) auto;
  height: 70vh;
  width: 80%;
  overflow-x: scroll;
}

.img-about {
  border-radius: var(--base-border);
  border: 4px solid var(--color-darken3);
  margin: var(--base-margin) auto;
  max-width: 50%;
  width: auto;
  height: auto;
}

.about-side-by-side {
  display: flex;
  width: 80%;
  margin: 0 auto;
  justify-content: space-between;
}

.about-text {
  margin-left: calc(4*var(--base-padding)) !important;
  margin-right: var(--base-padding) !important;
  width: 100% !important;
}

.spreadsheet-div {
  width: 80%;
  margin: 0 auto;
}

.spreadsheet-frame {
  margin: calc(2*var(--base-margin)) auto;
  width: 80%;
  min-height: 70vh;
}

.project-sec h2, .project-sec h3 {
  text-decoration: underline;
}

.project-sec h1, .project-sec h2, .project-sec h3 {
  color: var(--color-darken4);
}

.navigation {
  border-radius: var(--base-border);
  padding: var(--base-padding);
  margin: var(--base-margin);
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  display: flex;
  flex-direction: column;
}

.nav {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.nav-item {
  width: calc(100% - 2*var(--base-padding));
  padding: var(--base-padding);
  text-align: center;
  border-radius: 32px;
  margin: calc(var(--base-margin)/2) 0;
  background: var(--color);
  text-decoration: none;
  font-weight: bold;
  font-size: 24px;
  color: white;
}

.nav-item:hover {
  background: var(--color-darken4);
  color: white;
}

.nav-item.active {
  background: var(--color-darken3);
}

.front-nav-item {
  width: 100%;
  background: var(--color) !important;
  color: white !important;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  padding: var(--base-padding);
  margin: var(--base-margin);
  border: none;
  font-family: 'PT Sans Narrow', sans-serif;
  border-radius: 32px;
}

.front-nav-item:hover {
  background: var(--color-darken3);
}

.main-content {
  padding: var(--base-padding);
  margin: var(--base-margin);
  border-radius: var(--base-border);
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
  overflow-y: scroll;
}

.App {
  display: grid;
  grid-template-columns: 5vw 20vw 70vw 5vw;
  grid-template-rows: 5vh 90vh 5vh;
}

.nav-img {
  border-radius: var(--base-border);
  width: calc(20vw - 2*var(--base-padding) - 2*var(--base-margin));
  height: calc(20vw - 2*var(--base-padding) - 2*var(--base-margin));
  margin-bottom: var(--base-margin);
}

.project-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

.project-card {
  width: var(--project-card);
  height: var(--project-card);
  border-radius: var(--base-border);
  margin: var(--base-margin);
  display: grid;
}

.project-img {
  object-fit: cover;
  width: var(--project-card);
  height: var(--project-card);
  border-radius: var(--base-border);
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}

.project-link {
  justify-content: center;
  text-align: center;
  align-items: center;
  width: var(--project-card);
  height: var(--project-card);
  background: var(--color);
  color: white !important;
  border-radius: var(--base-border);
  display: none;
  font-weight: bold;
  text-decoration: none;
  font-size: 24px;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}

.dropdown-sec {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
}

.dropdown {
  border-radius: calc(var(--base-border)*2);
  padding: var(--base-padding);
  margin: calc(var(--base-margin)/2);
  width: 100%;
  font-weight: bold;
  text-align: center;
  font-size: 24pt;
}

.sub-dropdown {
  width: 80%;
  display: flex;
  justify-content: space-around;
}

.resume-pill {
  border-radius: calc(var(--base-border)*4);
  margin: calc(var(--base-margin)/2);
  padding: var(--base-padding);
}

.hide {
  display: none;
}

.testing {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: calc(var(--base-margin)*2) auto;
  justify-content: space-around;
}

.resume-stuff {
  width: 40%;
  margin: var(--base-margin);
}

.resume-stuff h2 {
  border-radius: var(--base-border);
  padding: var(--base-padding);
  width: calc(100% - 2*var(--base-padding));
  margin-top: 0;
  margin-bottom: 8px;
}

.resume-stuff ul {
  margin-top: 0;
  margin-bottom: 0;
}

.resume-stuff ul li {
}

.sub-about {
  display: flex;
  flex-direction: column;
}

.sub-about .links {
  display: flex;
  justify-content: space-around;
}

.sm-icon {
  width: 50px;
  height: 50px;
  color: var(--color);
}

.sm-icon:hover {
  color: var(--color-darken3);
}

.basic {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.basic p {
  text-align: center;
  font-size: 36px;
  width: 80%;
  height: fit-content;
  margin: 0 auto;
}

.fun-fact {
  background: var(--color-lighten4);
  border-radius: var(--base-border);
  padding: calc(2*var(--base-padding));
  margin: calc(2*var(--base-margin)) auto;
}

.animation {
  animation-timing-function: ease-in-out;
  padding: 16px 300px;
}

.front-nav {
  display: flex;
  justify-content: space-around;
  width: 80%;
}

#front-name-sent {
  font-size: 48px;
  margin-top: 16px;
  margin-bottom: 16px;
}

#front-name {
  font-weight: bold;
  font-family: 'Oleo Script', cursive;
  transform: translate(6rem);
  animation: up 500ms linear forwards;
}

@keyframes float {
  0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}

.animation {
  transform: translateY(0px);
  animation: float 6s ease-in-out infinite;
  width: 30%;
  border-radius: 50%;
  margin-top: 24px;
}

.animation img {
  fill: var(--color);
}

.big-space {
  height: calc(var(--base-padding)*4)
}

.line {
  margin-top: calc(var(--base-padding)*4);
  margin-bottom: calc(var(--base-padding)*4);
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  height: 2px;
  width: 80%;
  background-color: lightgray;
}

.project-card:hover .project-link {
  display: flex;
  opacity: 90%;
}

.quick-about {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 90%;
  margin: 0 auto;
}

.sec-quick-about {
  border-radius: var(--base-border);
  padding: var(--base-padding);
  margin: var(--base-margin);
}

.sec-quick-about h2 {
  text-decoration: underline;
}

.sec-quick-about p {
  font-size: 18px;
  text-align: center;
}


/* Resume Section */
.resume-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
}

.section-resume {
  width: calc(50% - 2*var(--base-margin));
  margin: var(--base-margin);
}

.section-resume h3 {
  text-align: center;
}

.website-link {
  color: var(--color-darken3);
}

.resume-img {
  display: flex;
  justify-content: center;
  align-self: center;
  max-width: 200px;
  width: 50%;
  margin: 0 auto;
}
.spreadsheet-frame {
  width: 100%;
  height: calc(100% - 4*var(--base-padding) - 42px);
}

.text-container {
  width: 70%;
  margin: var(--base-margin) auto;
}

/* Tables */
.d20-table {
  text-align: center;
  display: none;
}

.d20-table th {
  background: var(--color-darken4);
  color: white;
  border-radius: calc(var(--base-border)/2);
  padding: calc(var(--base-padding)/2);
}

.d20-table td {
  padding: calc(var(--base-padding)/2);
  border-radius: calc(var(--base-border)/2);
  background: var(--color-lighten3);
}

.d20-table tr:nth-child(2n) {
  background: var(--color-lighten4) !important;
}

.med-img {
  width: 70%;
  border-radius: 16px;
  margin: 10px auto;
  justify-content: center;
  text-align: center;
  align-items: center;
}


/***********************/
/* Small Laptop Sizing */
/***********************/
@media (max-width: 1050px) {
  .project-card {
    width: 250px;
    height: 250px;
  }

  .project-img {
    width: 250px;
    height: 250px;
  }

  .project-link {
    width: 250px;
    height: 250px;
  }

  .about-side-by-side {
    flex-direction: column;
  }

  .about-text {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 70% !important;
  }

  .animation {
    padding: 16px;
  }

  .gen-img {
    width: 80%;
  }

  .gen-section {
    flex-wrap: wrap;
  }
}


/*****************/
/* Tablet Sizing */
/*****************/
@media (max-width: 768px) {
  .App {
    /*grid-template-rows: 2vh 10vh 86vh 2vh;*/
    grid-template-rows: 2vh calc(20px + 4*var(--base-padding) + 2.5*var(--base-margin)) calc(96vh - 20px - 4*var(--base-padding) - 2.5*var(--base-margin)) 2vh;
  }

  .main-content {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 4;
    height: auto;
  }

  .navigation {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .nav-item {
    margin: calc(var(--base-margin)/4);
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }

  .nav {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
  }

  .nav-img { display: none; }

  .text-container {
    width: 80%;
  }

  .project-link {
    display: flex;
    opacity: 90%;
  }
}

/*******************/
/* Mobile Messages */
/*******************/
@media (max-width: 700px) {
  .mobile-tableau {
    display: block;
  }

  #vizContainer {
    display: none;
  }

  .text-container {
    width: 90%;
  }

  .section-resume {
    width: 100%;
  }

  .animation {
    padding: 0;
  }

  .fun-fact p {
    font-size: 24px !important;
  }

  .fun-fact {
    padding: 16px;
  }

  .front-nav {
    flex-wrap: wrap;
  }

  .gen-section {
    margin: 0;
    width: 100%;
  }

  .gen-text {
    margin: 0;
  }

  .gen-img {
    width: 100% !important;
  }
}

/*******************/
/* Mobile Sideways */
/*******************/
@media (max-height: 500px) {
  .nav-img {
    display: none;
  }

  .App {
    display: grid;
    grid-template-columns: 2vw 20vw 76vw 2vw !important;
    grid-template-rows: 2vh 96vh 2vh !important;
  }

  .nav {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .nav-item {
    width: calc(100% - 2*var(--base-padding) - var(--base-margin));
  }

  .navigation {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    padding: calc(var(--base-padding)/2);
  }

  .main-content {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
    height: auto;
  }

  .project-img, .project-link, .project-card {
    width: 175px;
    height: 175px;
  }

  .gen-img {
    max-height: 80vh;
  }

  .project-link {
    display: flex;
    opacity: 90%;
  }
}